import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { goToAdminPage, goToCollabPage, goToHomePage, goToLoginPage } from "../Routes/RouteFunctions";
import {jwtDecode} from 'jwt-decode';

export const useProtectedPage = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   const role = localStorage.getItem('role');

  //   const type = 'admin'

  //   console.log("token", token)
  //   console.log("role", role)
   

  //   if (!token) {
  //     goToLoginPage(navigate, role)
  //   }
  //   // else if (!token && role == null) {
  //   //   goToHomePage(navigate)
  //   // }
    
  // }
  useEffect(() => {
    const token = localStorage.getItem('token');
    const role = localStorage.getItem('role');

    console.log("Token:", token);
    console.log("Role:", role);

    if (!token) {
      console.log("Token não encontrado, redirecionando para o login...");
      goToLoginPage(navigate, role);
    }

    // try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        console.log("Token expirado, redirecionando para o login...");
        goToLoginPage(navigate, role);
      }

      // console.log("Token válido.");
    // } catch (error) {
    //   console.error("Erro ao decodificar o token:", error);
    //   goToLoginPage(navigate, role);
    // }
  }, [navigate]);
}
;